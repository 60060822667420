import { GetLocal, GetSession, Navigate, RemoveLocal, RemoveSession, SaveLocal, SaveSession, history } from '../helpers';
import { search } from './resource.service';
import { DISABLE_API_LOADER, appDefaults, icimUrlComponent } from '../config';
import axios from "axios";
import { isServer } from '../helpers/server.helper';
import { getCookie, setCookie } from './util.service';
import { bookingTypes, navigationTypes, userTypes } from '../redux/types';
import { setCountry, setLanguage, setMeetingPlace, setSite, userLogoutRedirect } from '../redux/actions';
import { policyTypes } from '../constants';
import _ from 'lodash';


const DEFAULT_API_LOADER_TIME = 500;

const login = (username, password) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            SaveLocal('user', JSON.stringify(user));

            return user;
        });
}

const loginWithCountry = (country, meetingPlace) => {
    return Promise.resolve().then(() => {
        return { country, meetingPlace, authentication: { loggedIn: true } };
    });
};

const loginWithSite = (site) => {
    return Promise.resolve().then(() => {
        SaveSession('site', JSON.stringify(site));
        return { site };
    });
}

const logout = () => {
    // remove user from local storage to log user out
    RemoveLocal('user');
}


const handleResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                !isServer && window?.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

/**
 * @function getUserDetails
 * @description API call to fetch user details based on the params given
 * @param {object} params The params on the basis of which the user details has to be found
 * @param {*} dispatch Function to send actions into the redux store
 * @returns {Promise<object>} For successful query, returns the response object that contains user data against the params used for search
 */
export const getUserDetails = (params, dispatch = () => { }, history = false) => {
    !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true });
    return new Promise((resolve, reject) => {
        search({
            ...params,
            method: "get_user_data",
            detail_level: 29
        })
            .then((resp) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, 200); }
                resolve(resp);
            }).catch((err) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, 200); }
                reject(err);
            });
    })
}

export const userService = {
    login,
    loginWithCountry,
    loginWithSite,
    logout
};

/**
 * @function syncIcimAndPlanyoUserData
 * @description for syncing the ICIM updated user profile information into PLANYO
 * @param {object} userDetails object containing user details like name, surName, email, phoneNumber etc.
 * @param {string} planyoUserId 
 * @param {function} dispatch a function to send actions into the redux store
 * @returns {void}
 */
export const syncIcimAndPlanyoUserData = (userDetails, userIdentifier, mode = "user_id", dispatch = () => { }) => {
    !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true });
    let prefix = userDetails?.phonePrefix?.replace(" ", "-")?.replace("+", "");
    let payload = {
        firstName: userDetails?.name,
        lastName: userDetails?.surname,
        phone: userDetails?.phone,
        phoneCountryCode: prefix,
        method: "modify_user",
        detail_level: null

    }
    if (userDetails?.email) {
        payload['newEmail'] = userDetails?.email
    }
    payload[mode] = userIdentifier;
    return new Promise((resolve, reject) => {
        search(payload, dispatch)
            .then((resp) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, DEFAULT_API_LOADER_TIME); }
                resolve(resp);
            }).catch((err) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, DEFAULT_API_LOADER_TIME); }
                reject(err);
            });
    })
}

/**
 * @function searchUserByIcimId
 * @description API call to search a user based on an ICIM ID
 * @param {string} icimID The ICIM ID to run the search on
 * @param {*} dispatch Function to send actions into the redux store
 * @returns {Promise<object>} For successful query, returns the response object that contains user data for which this ICIM ID is stored against.
 */
export const searchUserByIcimId = (icimID, dispatch = () => { }) => {
    !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true });
    return new Promise((resolve, reject) => {
        search({ method: "search_users_by_custom_property", detail_level: 3, propertyName: 'icim_id', propertyValue: icimID, siteId: 'all' })
            .then((resp) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, DEFAULT_API_LOADER_TIME); }
                resolve(resp);
            }).catch((err) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, DEFAULT_API_LOADER_TIME); }
                reject(err);
            });
    })
};

/**
 * @function setIcimId
 * @description API call to set ICIM ID of user in planyo account
 * @param {string} value The ICIM ID to set
 * @param {string} planyoUserId The Planyo ID of the user
 * @param {*} dispatch Function to send actions into the redux store
 * @returns {Promise<object>} For successful query, returns the response object that contains user data for which this ICIM ID is stored against.
 */
export const setIcimId = (value, planyoUserId, dispatch = () => { }) => {
    dispatch({ type: "apiLoader", payload: true });
    return new Promise((resolve, reject) => {
        search({ method: "set_custom_property", type: 'user', name: 'icim_id', value, customPropertyId: planyoUserId, setPropertyFor: 'user' })
            .then((resp) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, DEFAULT_API_LOADER_TIME); }
                resolve(resp);
            }).catch((err) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, DEFAULT_API_LOADER_TIME); }
                reject(err);
            });
    })
};

/**
 * @function captureICIMUserCode
 * @description After coming back to Landing Page from ICIM login process, store the user code from the url generated by ICIM, and then store it in session storage 
 * @returns {string} returns the ICIM user code captured from url. If no code is generated, then it returns an empty string
 */
export const captureICIMUserCode = (capturedCode = {}) => {
    if (capturedCode?.code === null) {
        const capturedUrl = window?.location?.href?.split("code=") || [];
        if (capturedUrl.length > 1) {
            const codes = capturedUrl[1]?.split("&") || null;
            capturedCode = {
                code: codes[0],
                magicLink: false
            }
            if (capturedUrl[0]?.includes("magic_link")) {
                capturedCode['magicLink'] = true;
            }
            // the below code is to set the user as authenticated in case of magic link but it is redunant for rest of the cases
            // the issue has been taken care of by authencating the user in App.js based on userCode or authencation object
            // SaveSession("authentication", JSON.stringify({ loggedIn: true }));
        }
    }
    if (capturedCode?.code) {
        if (!capturedCode?.magicLink) {
            capturedCode['magicLink'] = JSON.parse(GetLocal("userCode") || '{}')?.magicLink ? true : false;
        }
        SaveLocal("userCode", JSON.stringify(capturedCode));
        return capturedCode;
    } else {
        return "";
    }
}

/**
 * @function redirectUser
 * @description it restores the previous session of the user and redirects the user to the route specified in the redirectRoute object which is stored in Session
 * @param {object} param0 object that contains:
 *                        -authentication (object): Redux object that stores the user logged in status and user details
 *                        -dispatch (function): function for dispatching Redux actions
 *                        -history (object): provides the browser history object
 * @returns {void}
 */
export const redirectUser = ({ dispatch, history, escDomains }) => {
    const redirectRoute = GetLocal("redirectRoute") && GetLocal("redirectRoute") !== 'undefined' ? JSON.parse(GetLocal("redirectRoute")) : null;
    const sessionAuthentication = GetLocal("authentication") && GetLocal("authentication") !== 'undefined' ? JSON.parse(GetLocal("authentication")) : null;
    const loggedIn = sessionAuthentication?.loggedIn || false;
    const userCode = JSON.parse(GetLocal("userCode") || '{}')?.code;
    const routeId = parseInt(redirectRoute?.routeId);
    const conditionForEditProfile = (loggedIn && userCode && redirectRoute?.policyType === policyTypes?.EDIT_PROFILE) ? true : false;
    const icimActionError = GetSession("icimActionError") || null;
    if (userCode && redirectRoute?.policyType === policyTypes?.P_UPDATE && !icimActionError) {
        redirectRoute["displayModal"] = true;
        SaveLocal("redirectRoute", JSON.stringify(redirectRoute));
    }
    //  in case of magic link:
    /*
        there are 2 possible cases:
        1. when user opens the link in the same tab
            we will have redirectRoute + authentication
            the check should be redirectRoute + authentication + additional condition written already
        2. when user opens the link in some other tab or browser
            we will not have any information about redirectRoute + authentication
            the check should be redirectRoute should not exist + authentication should not exist + userCode should be there
    */
    let shouldRedirect = (
        (redirectRoute && Object?.keys(redirectRoute)?.length > 0) &&
        (conditionForEditProfile ||
            ((redirectRoute?.tokenExpire || !loggedIn) && userCode) ||
            redirectRoute.isLoggedOut)
    ) ? true : false;

    shouldRedirect = shouldRedirect && redirectRoute?.meetingPlace && redirectRoute?.country;

    if (shouldRedirect) {
        SaveSession("meetingPlace", JSON?.stringify(redirectRoute?.meetingPlace));
        SaveSession("country", JSON?.stringify(redirectRoute?.country));
        SaveSession("selectedLanguage", redirectRoute?.selectedLanguage);
        SaveSession("site", JSON?.stringify(redirectRoute?.site || null));

        const bookingDetails = JSON?.parse(GetLocal("bookingSession")) || null;

        const actions = [
            dispatch(setMeetingPlace(redirectRoute?.meetingPlace)),
            dispatch(setLanguage(redirectRoute?.selectedLanguage)),
            dispatch(setCountry(redirectRoute?.country)),
            dispatch(setSite(redirectRoute?.site)),
        ];

        Promise.all(actions).then(() => {
            setTimeout(() => {
                Navigate(routeId, { dispatch, history, id: routeId !== 40000 ? bookingDetails?.resourceId : null });
            }, 500);
        });
    } else {
        if (!isServer && appDefaults.meetingPlace && appDefaults?.domain && appDefaults?.language && appDefaults?.countryCode && window && window?.location && !escDomains.includes(window.location.hostname) && _.isEmpty(appDefaults?.mpids || [])) {
            window.location.href = `${appDefaults?.domain}/${appDefaults?.language?.toLowerCase()}-${appDefaults?.countryCode?.toUpperCase()}/${appDefaults.meetingPlace?.id}/gbp/book`;
        }
    }


}

/**
 * @function createICIMRedirectUrl
 * @description function for creating the ICIM redirect URL based on the ICIM configuration and user selected parameters
 * @param {*} param0 object that contains:
 *                      - tenantId
 *                      - clientId
 *                      - brandId: contains the meetingPlace brand-id
 *                      - selectedLangauge: language selected by the user
 *                      - policy: that defines the user action(login/SignUp or edit profile)
 * @returns {string} returns the formed ICIM URL
 */
export const createICIMRedirectUrl = ({ brandId, selectedLanguage, policy }) => {
    // let ICIMUrl = `${icimUrlComponent}/${tenantId}/oauth2/v2.0/authorize?p=${policy}&client_id=${clientId}&nonce=defaultNonce&redirect_uri=${window.location.origin}&scope=openid&response_type=code&brand_id=${brandId}&ui_locales=${selectedLanguage}`;
    if (selectedLanguage === 'pt') {
        selectedLanguage = 'pt-pt';
    }
    let ICIMUrl = `${icimUrlComponent}/${process.env.REACT_APP_ICIM_TENANT_ID}/oauth2/v2.0/authorize?p=${policy}&client_id=${process.env.REACT_APP_ICIM_CLIENT_ID}&nonce=defaultNonce&redirect_uri=${window.location.origin}&scope=openid&response_type=code&brand_id=${brandId}&ui_locales=${selectedLanguage}`;

    return ICIMUrl;
}

/**
 * @function checkUserToken
 * @description checks whether the user token exists in cookies. If token has expired, then user is redirected to ICIM login page
 * @returns {void}
 */
export const checkUserToken = (policy) => {
    let userToken = getCookie("userToken") || null;
    if (!userToken) {
        const config = JSON.parse(GetSession("config")) || null;
        // const page = JSON.parse(GetSession('currentPage')) || null;
        const meetingPlace = JSON.parse(GetSession("meetingPlace")) || null;
        const country = JSON.parse(GetSession("country")) || null;
        const selectedLanguage = GetSession("selectedLanguage") || null;
        const site = JSON.parse(GetSession("site")) || null;
        let redirectRoute = {
            routeId: navigationTypes?.PERSONALAREAPAGE,
            meetingPlace: meetingPlace,
            country: country,
            selectedLanguage: selectedLanguage,
            site: site,
            policyType: policyTypes?.LOGIN_SIGNUP,
            tokenExpire: true
        }
        SaveLocal("redirectRoute", JSON.stringify(redirectRoute));
        const currentPage = JSON.parse(GetSession("currentPage"));
        //pushing current page to history
        history?.push(window?.location?.pathname, { key: `${currentPage?.key}-${new Date()?.getTime()}`, page: JSON.stringify(currentPage) });
        // navigate the user to ICIM
        const ICIMUrl = createICIMRedirectUrl({ brandId: meetingPlace?.brand_id, selectedLanguage: selectedLanguage, policy });
        window?.location?.replace(ICIMUrl);
    }
}

/**
 * @function setMarketingCheck
 * @description wrapper function for making user marketing consent api
 * @param {Object} param0 Object containing planyo-user-id and user consent to marketing (either "Yes" or "No")
 * @returns {Promise}
*/
export const setMarketingConsent = ({ userId, value }) => {
    return search({
        method: "set_custom_property",
        type: 'user',
        name: 'marketing_updates_consent',
        value,
        customPropertyId: userId,
        setPropertyFor: "user"
    });
}

/**
 * @function logoutUser
 * @description function that removes the current user session and redirect the user to General Booking Page of the selected meeting place
 * @param {*} dispatch Function to send actions into the redux store
 * @param {object} app redux-object containing application configuration  
 */
export const logoutUser = (dispatch, app) => {
    dispatch({ type: userTypes.LOGOUT });
    dispatch({ type: bookingTypes.CLEAN_BOOKING_DETAILES })
    const userToken = getCookie("userToken");
    // remove userCode and userToken and authentication status
    RemoveLocal("userCode");
    RemoveLocal("authentication");
    RemoveLocal("bookingSession");
    setCookie("userToken", "", -1);
    //fire API for ICIM logout
    const policy = JSON.parse(GetLocal('redirectRoute'))?.policyType || policyTypes.LOGIN_SIGNUP;
    const redirectRoute = {
        routeId: navigationTypes?.GENERALBOOKINGPAGE,
        meetingPlace: app?.meetingPlace,
        country: app?.country,
        selectedLanguage: app?.selectedLanguage,
        site: app?.site,
        isLoggedOut: true
    }
    SaveLocal("redirectRoute", JSON.stringify(redirectRoute));
    userLogoutRedirect(policy, userToken);
};

/**
 * @function setAlternativeEmail
 * @description function for setting the alternate email id custom property in planyo against user id
 * @param {object} param0 object containing emailId and user id 
 * @returns {function} returns search function that process the API call
 */
export const setAlternativeEmail = ({ emailId, planyoUserId }) => {
    return search({
        method: "set_custom_property",
        type: 'user',
        name: 'alternate_email',
        value: emailId,
        customPropertyId: planyoUserId,
        setPropertyFor: 'user'
    })
}